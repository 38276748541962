const artistsList = [
  {
    name: "Blaine",
    files: "Blaine",
    fullName: "Blaine Reum",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/blaine-pic.jpg",
    about:
      "My name is Blaine Reum. I'm 28 years old and from the Northshore of Oahu. I have been in love with tattooing practically most of my life. Any time not spent training jiujitsu, I try to dedicate to the practice of creating art. I picked up my first machineat 16 years old and have been a student of the science ever since.I enjoy tattooing any style of work from fine line, black & grey, realism, traditional American or Japanese—you want it, I'll tattoo it! But if you were to ask my preference, I'll always say 'Anything with bones or blades!'",
    instagram: "https://www.instagram.com/tattoos.by.blaine",
    email: "",
    facebook: "",
    url: "/gallery/blaine",
  },
  {
    name: "James",
    files: "James",
    fullName: "James",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/James.jpeg",
    about: "Check out my artwork below.",
    instagram: "",
    email: "",
    facebook: "",
    url: "/gallery/james",
  },
  {
    name: "Marco",
    files: "Marco",
    fullName: "Marco Perez",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/Marco.jpeg",
    about:
      "Marco is a California Native who enjoys many types of art media. He has been tattooing for many years, several of them here in the beautiful islands of Hawaii. He makes every effort to deliver your requests and is more than happy to educate and lead you in the right direction to create a successful piece of tattoo art that will last many years beyond your time here in Hawaii. To check out more of his work, check out his Instagram. Remember: 'Tattoos are connected to memories that will last a lifetime!' Let Marco help you make it an awesome one!",
    instagram: "https://www.instagram.com/lightcodetatu",
    email: "",
    facebook: "",
    url: "/gallery/marco",
  },
  {
    name: "Jay",
    files: "Jay",
    fullName: "Jay Rascal",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/Jay.jpg",
    about: "To view more of my work, check out my Instagram below!",
    instagram: "https://instagram.com/inkedbyjay_5150",
    email: "",
    facebook: "",
    url: "/gallery/jay",
  },
  {
    name: "Shaye",
    files: "Shaye",
    fullName: "Shaye Iwanuma",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/shaye.jpg",
    about:
      "Hi my name is Shaye Iwanuma, I was born and raised here in Hawaii. I've always loved making art and now get to do it as my full-time career! I specialize in fine line, micro realism, floral, black and grey, abstract, and Japanese. check out some of my work!",
    instagram: "https://www.instagram.com/artbyshayee",
    email: "",
    facebook: "",
    url: "/gallery/shaye",
  },
  {
    name: "Mako",
    files: "Mako",
    fullName: "Mako Zane",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/mako.jpg",
    about:
      "My name is Mako Zane. I am born and raised on the island of Oahu. I have been creating artwork for most of my life and have a degree in studio art. I've been tattooing for 8 years professionally and continue to this day to be a student of the craft. I am currently a sponsored Emalla pro-team and Recovery Aftercare artist and have been featured tattooing on MTV. I am a versatile artist but specialize in tribal, geometric, mandala, floral, and black and grey. Please check out some of my work below!",
    instagram: "",
    email: "",
    facebook: "",
    url: "/gallery/mako",
  },
  {
    name: "Sean",
    files: "Sean",
    fullName: "Sean Martin",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/Sean.jpg",
    about:
      "Aloha! My name is Sean Martin and I’ve been tattooing since 2006. I started my tattoo career in Southern California until 2013 when I moved to Lahaina, Maui. I’ve always loved American traditional and Japanese traditional styles of tattooing and artwork. Which are def my favorite styles to tattoo. Along with Polynesian. When I’m not busy tattooing I like to spend my time drawing and painting. Check out my work below, hope you enjoy! Mahalo and Aloha!",
    instagram: "https://www.instagram.com/sean_tattooer",
    email: "",
    facebook: "",
    url: "/gallery/sean",
  },
  {
    name: "Kaitlin",
    files: "kaitlin",
    fullName: "Kaitlin B.",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/Kaitlin.jpg",
    about:
      "Introducing Kaitlin, our talented piercer with a passion for body modification. With meticulous attention to detail, she creates personalized piercings that reflect your unique style. Committed to hygiene and safety, Kaitlin ensures a professional experience using high-quality materials. Trust her expertise to enhance your body with stunning piercings.",
    instagram: "",
    email: "",
    facebook: "",
    url: "/gallery/kaitlin",
  },
  {
    name: "Smokey",
    files: "Smokey",
    fullName: "Smokey R.",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/smokey.jpg",
    about:
      "Smokey grew up in a Hispanic heritage in Southern California. Life was far from easy, as the only seeming escape for him was the allure of street gangs. It was amidst this environment that Smokey first delved into the world of tattooing at the tender age of 14. Using a makeshift tattoo machine crafted from a walk-man motor, an eraser, and a guitar string for a needle, he began tattooing his friends. However, the path of the street gang proved tumultuous, leading Smokey down a road marked by trouble and eventual prison time. Yet, it was during this period behind bars that Smokey honed his craft, creating tattoos for fellow inmates until his release. Post-prison life was no less challenging, with a tarnished record making it difficult for Smokey to secure employment. Rejection became a familiar companion, with even major companies like McDonald’s turning him away. It was a stark realization for Smokey that life beyond the gang held its own set of formidable challenges, propelling him towards the quest for a better life. As Smokey matured into adulthood, his passion for art found expression in the world of professional tattooing. He embraced this newfound calling with fervor, using it as a canvas to share his talent with the world. From traveling to various locales to showcase his impressive artistry, to consistently striving to meet and exceed the expectations of his clientele, Smokey has remained dedicated to his craft. His ultimate goal remains steadfast—to ensure every customer walks away 100% satisfied with his meticulous attention to detail, each tattoo a masterpiece orchestrated with pride. Through his art, Smokey not only transforms skin but also uplifts spirits, offering a testament to the power of creativity and determination in the face of adversity.",
    instagram: "https://www.instagram.com/kingtattoosmokey1",
    email: "",
    facebook: "",
    url: "/gallery/smokey",
  },
  {
    name: "Hector",
    files: "Hector",
    fullName: "Hector Moreno",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/hector.jpg",
    about:
      "Hey, I'm Hector. With 19 years of tattooing experience, I specialize in crafting custom designs that stand out. Whether it's intricate black and grey work or bold color pieces, I bring precision and creativity to every tattoo. Based in Hawaii, I offer a unique experience for those visiting the islands. Let's turn your vision into a badass piece of art you'll wear with pride and make your trip to Hawaii unforgettable.",
    instagram: "https://www.instagram.com/don_coyote_tattoos",
    email: "",
    facebook: "",
    url: "/gallery/hector",
  },
  {
    name: "Dan",
    files: "Dan",
    fullName: "Dan",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/dan.jpg",
    about:
      "Meet Dan. He makes incredible tattoos in any style but is particularly drawn to American Traditional and Blackwork. He’s been Tattooing professionally for 7 years in Seattle, Denver, and Honolulu. Ask him about his cattle dog, motorbike, or book he’s reading.",
    instagram: "https://www.instagram.com/danstenderart",
    email: "",
    facebook: "",
    url: "/gallery/dan",
  },
  {
    name: "Nate",
    files: "NateB",
    fullName: "Nate Brothers",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/nateB.jpg",
    about:
      "Aloha! I have been tattooing since 2006 and have been blessed to call Hawaii my home for the past 8 years. I am a very versatile artist, with knowledge amongst a wide range of styles! Along with being a tattooer, I am also a father, husband, scuba instructor, and an ocean lover!",
    instagram: "https://www.instagram.com/na_brotha",
    email: "",
    facebook: "",
    url: "/gallery/nate",
  },
  {
    name: "Marcelo",
    files: "Marcelo",
    fullName: "Marcelo Souza",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/marcelo.jpg",
    about:
      "Aloha, I’m Marcelo Souza. I'm from Brazil. Since I was a kid, I've been an artist, creating handmade signs, murals, paintings, and painting surfboards in my beach town. I also work with airbrushing. After living in Europe and Costa Rica for over two years, I moved to Maui in 2002. While painting a mural in Maui, I was invited by a Hawaiian man to visit his wife's tattoo studio and start a tattoo apprenticeship. Since then, I've been tattooing in Hawaii, Australia, New Zealand, Costa Rica, Brazil, and other places. When I'm not busy tattooing, I love working on freehand painted clothes, shoes, paintings, and drawing posters. You can check out my tattoos and other artwork on my Instagram.",
    instagram: "https://www.instagram.com/mauiinktattoos",
    email: "",
    facebook: "",
    url: "/gallery/marcelo",
  },
  {
    name: "Laura",
    files: "Laura",
    fullName: "Laura",
    location: "Kalakaua",
    pic: "https://sacredarthawaii.com/images/artists/laura.jpg",
    about:
      "I'm Laura Boyd, a passionate and dedicated professional body piercer, originally from South Carolina, and I've spent the past two years living in Hawail. I love everything about the islands and draw inspiration from their beauty and culture. I have a deep love for piercing and building lasting relationships with return clients, and l also enjoy meeting new people and making each experience personable and welcoming.",
    instagram: "https://www.instagram.com/piercingsby.laura",
    email: "",
    facebook: "",
    url: "/gallery/laura",
  },
];

export default artistsList;
